.competitionsPage {
  overflow: hidden;
  display: flex;
  flex-direction: column; 
}
.filter__chip {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  gap:10px;
  overflow-y: auto;
  margin: 0 16px;
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

.filter__chip::-webkit-scrollbar {
  display: none;
}

.filter__dot {
  border: 3px solid #9ACE7C;
  border-radius: 3px;

}


.body__container {
  padding:20px;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  font-family: var(--secondary-font);
  text-align: center;
  margin-left: 5px;
}

.competition__catogeory {
  border-right: 2px solid #ffffff;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-y: scroll;
  flex-shrink: 0;
  padding: 1.5rem 0;
  width: 250px;
  position: fixed;
  -ms-overflow-style: none; 
  scrollbar-width: none;   
}

.competition__catogeory::-webkit-scrollbar {
  display: none;
}

.competition__catogery_inner {
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  margin-right: 15px;
  gap: 1rem;
}

.competition__catogery_card {
 margin: 20px;
 margin-top: 50px;
}
.competition__catogery_card__selected {
 margin: 20px;
 margin-top: 50px;
 filter: drop-shadow(0px 0px 2px #9EEFB372) drop-shadow(0px 0px 2px #9EEFB372)    drop-shadow(0px 0px 1px #9EEFB372) drop-shadow(0px 0px 9px#9EEFB372)    drop-shadow(0px 0px 1px #9EEFB372) drop-shadow(0px 0px 1px #9EEFB372);
}
.card__container {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 30px;
  width: 100%;
}

.row{
  display: flex;
  flex-direction:row;
  gap: 20px;
  justify-content:space-around;
  align-items:center;
  margin-top: 24px;
  padding-left: 250px;
  flex-wrap: wrap;
  width: 100%;
}
.col{
  display: flex;
  flex-direction: column;
  margin-bottom:20px;
  margin-right: 30px;
}




@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {
  .competition__catogeory {
    width: 200px;
  }
  .row {
    padding-left: 200px;
  }
  .competition__catogery_card {
    margin: 10px;
    margin-top: 30px;
   }
   .competition__catogery_card__selected {
    margin: 10px;
    margin-top: 30px;
   }
}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}



@media screen and (max-width: 600px) {
  .row {
    margin: 16px;
    padding-left: 0;
  }
}