.loading {
    min-height: 100vh;
    background-image: url("../../assets/svg/bg_grains.svg");
    background-color: #02121C; 
    background-position: center; 
    background-repeat: repeat;
    background-size: cover;
    -webkit-animation: backgroundScroll 15s linear infinite;
    -webkit-animation-fill-mode: forwards;
    animation: backgroundScroll 15s linear infinite;
    animation-fill-mode: forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
